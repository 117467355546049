<template>
  <Layout>
    <section class="csms-member">

      <!-- Page Title Start -->
      <PageHeader :title="title" />
      <!-- Page Title End -->

      <!-- Summary Start -->
      <div class="row">
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('member', 'memberStatus')"
                       :items="[
                         { name: $t('common.total'), value: infoItems.memberTotal },
                         { name: $t('common.newToday'), value: infoItems.memberNewToday },
                         { name: $t('member.todayLogin'), value: infoItems.memberTodayLogin }
                       ]" />
        </div>
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('member', 'planType')"
                       :items="[
                         { name: $t('common.ultraPlan'), value: infoItems.ultraPlan },
                         { name: $t('common.generalPlan'), value: infoItems.generalPlan }
                       ]" />
        </div>
        <div class="col-xl-4">
          <SummaryCard :title="$_formatter.convertedTitle('member', 'provider')"
                       :items="[
                         { name: $t('common.email'), value: infoItems.email },
                         { name: $t('common.google'), value: infoItems.google },
                         { name: $t('common.apple'), value: infoItems.apple }
                       ]" />
        </div>
      </div>
      <!-- Summary End -->

      <!-- Search Area Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <!-- // Search Input -->
                <div class="col-xl-10 ion-mb-mb-100">
                  <div class="row">
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-plan-type">{{ $t('member.planType') }}</label>
                      <multiselect id="member-search-plan-type"
                                   v-model="searchForm.planType"
                                   label="text" track-by="value"
                                   :options="planTypeOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-provider">{{ $t('member.provider') }}</label>
                      <multiselect id="member-search-provider"
                                   v-model="searchForm.provider"
                                   label="text" track-by="value"
                                   :options="providerOptions"
                                   selectLabel=""
                                   selectedLabel=""
                                   deselectLabel=""
                                   :searchable="false" :allow-empty="false" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-name">{{ $t('member.memberName') }}</label>
                      <input type="text" class="form-control" id="member-search-name"
                             v-model="searchForm.memberName" />
                    </div>
                    <div class="col-xl-3">
                      <label class="form-label" for="member-search-email">{{ $t('member.email') }}</label>
                      <input type="text" class="form-control" id="member-search-email"
                             v-model="searchForm.email" />
                    </div>
                  </div>
                </div>
                <!-- // Search Button -->
                <div class="col-xl-2 align-content-end">
                  <div class="float-end">
                    <b-button variant="secondary" class="me-3" @click="reset">
                      <i class="uil uil-redo me-2"></i>
                      <span>{{ $t('btn.reset') }}</span>
                    </b-button>
                    <b-button variant="primary" @click="search">
                      <i class="uil uil-search me-2"></i>
                      <span>{{ $t('btn.search') }}</span>
                    </b-button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Search Area End -->

      <!-- List Start -->
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">

              <div class="row mb-3 align-items-end">
                <!-- // List PerPage Count -->
                <div class="col-auto">
                  <PerPageSelector v-model="pagination.perPage"
                                   :option="[10, 25, 50]"
                                   @change="onPerPageChange" />
                </div>
                <!-- // Total Count -->
                <div class="col me-2">
                  <div class="float-end">
                    <total-count :total-count="pagination.totalCount" />
                  </div>
                </div>
              </div>

              <!-- // List -->
              <div class="table-responsive">
                <b-table class="text-nowrap align-middle" bordered hover
                         show-empty :empty-text="$t('member.noMember')"
                         :fields="fields" :items="items">
                  <template #head()="data">
                    <span v-if="!$_utils.isEmpty(data.label)">{{ $t(`member.${data.column}`) }}</span>
                  </template>

                  <template #cell(planType)="data">
                    {{ $_formatter.conversionPlanName(data.item.planType) }}
                  </template>
                  <template #cell(memberName)="data">
                    {{ !$_utils.isEmpty(data.item.memberName) ? data.item.memberName : '-' }}
                  </template>
                  <template #cell(provider)="data">
                    {{ providerTitle(data.item.provider) }}
                  </template>
                  <template #cell(mobilePhoneNo)="data">
                    {{ !$_utils.isEmpty(data.item.mobilePhoneNo) ? data.item.mobilePhoneNo : '-' }}
                  </template>
                  <template #cell(quitDate)="data">
                    {{ data.item.quitDate ? data.item.quitDate : '-' }}
                  </template>
                  <template #cell(recentLoginTimestamp)="data">
                    {{ data.item.recentLoginTimestamp ? data.item.recentLoginTimestamp : '-' }}
                  </template>
                  <template #cell(etc)="data">
                    <router-link role="button"
                                 :to="{path: `/app/member/view/${data.item.memberNo}`}"
                                 class="btn btn-sm btn-outline-primary w-sm">{{ $t('btn.view') }}</router-link>
                  </template>
                </b-table>
              </div>

              <!-- // Pagination -->
              <div class="row mt-3">
                <div class="col-xl-12">
                  <b-pagination size="sm" class="mb-0" align="center"
                                :total-rows="pagination.totalCount"
                                :per-page="pagination.perPage"
                                v-model="pagination.currentPage"
                                @change="onPageChange" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- List End -->

    </section>
  </Layout>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect';
import Layout from '@/views/layouts/Main.vue';
import PageHeader from '@/components/PageHeader.vue';
import PerPageSelector from '@/views/components/PerPageSelector.vue';
import TotalCount from '@/views/components/TotalCount.vue';
import SummaryCard from '@/views/components/SummaryCard.vue';

export default {
  components: {
    Multiselect, Layout, PageHeader, PerPageSelector, TotalCount, SummaryCard
  },
  data() {
    return {
      title: 'manageUser',
      infoItems: {
        memberTotal: 0,
        memberNewToday: 0,
        memberTodayLogin: 0,
        ultraPlan: 0,
        generalPlan: 0,
        email: 0,
        google: 0,
        apple: 0
      },
      planTypeOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('common.ultraPlan'), value: 10000000000031 },
        { text: i18n.t('common.generalPlan'), value: 10000000000030 },
      ],
      providerOptions: [
        { text: i18n.t('common.total'), value: null },
        { text: i18n.t('common.email'), value: 'local' },
        { text: i18n.t('common.google'), value: 'google' },
        { text: i18n.t('common.apple'), value: 'apple' }
      ],
      searchConditions: [],
      sortDirection: 'DESC',
      pagination: {
        currentPage: 1,
        totalCount : 0,
        perPage: 10
      },
      searchForm: {
        planType: { text: i18n.t('common.total'), value: null },
        provider: { text: i18n.t('common.total'), value: null },
        memberName: null,
        email: null
      },
      fields: [
        { key: 'rnum', label: 'No.', class: 'text-center' },
        { key: 'planType', label: '회원 플랜', class: 'text-center' },
        { key: 'memberName', label: '이름', class: 'text-center' },
        { key: 'provider', label: '가입 유형', class: 'text-center' },
        { key: 'email', label: '이메일', class: 'text-center' },
        { key: 'mobilePhoneNo', label: '연락처', class: 'text-center' },
        { key: 'created', label: '등록일', class: 'text-center' },
        { key: 'quitDate', label: '탈퇴일', class: 'text-center' },
        { key: 'recentLoginTimestamp', label: '마지막 로그인', class: 'text-center' },
        { key: 'etc', label: '', class: 'text-center' }
      ],
      items: null
    }
  },
  mounted() {
    const vm = this;

    vm.showOverlay();
    vm.getItems();
  },
  methods: {
    ...loadingMethods,
    providerTitle(_type) {
      switch (_type) {
        case 'local': return i18n.t('common.email');
        case 'apple': return i18n.t('common.apple');
        case 'google': return i18n.t('common.google');
        default: return '-';
      }
    },
    async getItems() {
      const vm = this;

      await Promise.all([
        vm.getInfoItems(),
        vm.getListItems()
      ]);
      vm.hideOverlay();
    },
    async reset() {
      const vm = this;

      vm.searchForm = {
        planType: { text: i18n.t('common.total'), value: null },
        provider: { text: i18n.t('common.total'), value: null },
        memberName: null,
        email: null
      };
      vm.searchConditions = [];

      vm.showOverlay();
      await vm.getListItems();
      vm.hideOverlay();
    },
    async search() {
      const vm = this;

      vm.searchConditions = [];

      if (!this.$_utils.isEmpty(vm.searchForm.planType.value)) {
        vm.searchConditions.push({ searchKey: 'memberPlanId', searchValue: vm.searchForm.planType.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.provider.value)) {
        vm.searchConditions.push({ searchKey: 'provider', searchValue: vm.searchForm.provider.value });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.memberName)) {
        vm.searchConditions.push({ searchKey: 'memberName', searchValue: vm.searchForm.memberName });
      }

      if (!this.$_utils.isEmpty(vm.searchForm.email)) {
        vm.searchConditions.push({ searchKey: 'email', searchValue: vm.searchForm.email });
      }

      vm.pagination.currentPage = 1;
      vm.showOverlay();
      await vm.getListItems();
      vm.hideOverlay();

      /*if (this.$_utils.isEmpty(vm.searchForm.planType.value)
          && this.$_utils.isEmpty(vm.searchForm.provider.value)
          && this.$_utils.isEmpty(vm.searchForm.memberName)
          && this.$_utils.isEmpty(vm.searchForm.email)) {
        await Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.searchConditions'),
          confirmButtonText: i18n.t('btn.close')
        });
      } else {
        vm.searchConditions = [];

        if (!this.$_utils.isEmpty(vm.searchForm.planType.value)) {
          vm.searchConditions.push({ searchKey: 'memberPlanId', searchValue: vm.searchForm.planType.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.provider.value)) {
          vm.searchConditions.push({ searchKey: 'provider', searchValue: vm.searchForm.provider.value });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.memberName)) {
          vm.searchConditions.push({ searchKey: 'memberName', searchValue: vm.searchForm.memberName });
        }

        if (!this.$_utils.isEmpty(vm.searchForm.email)) {
          vm.searchConditions.push({ searchKey: 'email', searchValue: vm.searchForm.email });
        }

        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }*/
    },
    getInfoItems() {
      const vm = this;
      const _url = '/onmapi/view/Summary/member';

      return new Promise((resolve, reject) => {
        http.get(_url).then(res => {
          const _item = res.data.item;
          vm.infoItems = {
            ...vm.infoItems,
            memberTotal: _item.memberTotalCount,
            memberNewToday: _item.memberTodayCount,
            memberTodayLogin: _item.memberTodayLoginCount,
            ultraPlan: _item.memberPlan.ultraCount,
            generalPlan: _item.memberPlan.normalCount,
          }

          if (_item.registerTypeCount) {
            _item.registerTypeCount.forEach(val => {
              if (val.provider === 'apple') {
                vm.infoItems.apple = val.count;
              } else if (val.provider === 'google') {
                vm.infoItems.google = val.count;
              } else if (val.provider === 'local') {
                vm.infoItems.email = val.count;
              }
            });
          }
          resolve(res);
        }).catch(err => {
          console.error('Member Info Error :: ', err)
          reject(err);
        });
      });
    },
    getListItems() {
      const vm = this;
      const _url = '/onmapi/view/MemberInfo/list';

      return new Promise((resolve, reject) => {
        http.post(_url, {
          page: vm.pagination.currentPage,
          limit: vm.pagination.perPage,
          searchConditions: vm.searchConditions,
          sortCondition: { sortDirection: vm.sortDirection }
        }).then(res => {
          vm.items = res.data.items;
          vm.pagination.totalCount = res.data.totalCount;
          resolve(res);
        }).catch(err => {
          console.error('Member List Error :: ', err)
          reject(err);
        });
      });
    },
    async onPerPageChange() {
      const vm = this;

      if (vm.pagination.totalCount > 1) {
        vm.pagination.currentPage = 1;
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    },
    async onPageChange(val) {
      const vm = this;
      vm.pagination.currentPage = val;

      if (vm.pagination.totalCount > 1) {
        vm.showOverlay();
        await vm.getListItems();
        vm.hideOverlay();
      }
    }
  }
}
</script>

<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
